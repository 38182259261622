import React, { useEffect, useState } from "react";
import { Tab, } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as actions from "../_redux/incidents/Actions";

import * as auth_actions from "../../Auth/_redux/authRedux";
import { refresh_token, setAvailability, getUserByToken } from "../../Auth/_redux/authCrud";
import GoogleMapReact from 'google-map-react';
import SVG from "react-inlinesvg";
import { toast } from 'react-toastify';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import config from "../../../../config"
import Lists from "./list";

export function Incidents(props) {

    const dispatch = useDispatch();
    const [key, setKey] = useState("active");
    const [checked, setChecked] = useState(false);


    const { user, active_danger, pending_danger, categories } = useSelector(
        (state) => ({
            user: state.auth.user,
            categories: state.alert.categories,
            active_danger: state.alert.active_danger,
            pending_danger: state.alert.pending_danger,
        }),
        shallowEqual
    );
    useEffect(() => {
        getUserByToken().then((data) => {
            if (data.status === 200) {
                if (data.data.data) {
                    dispatch(auth_actions.actions.fulfillUser(data.data.data))
                }
            }
        })
    }, [])
    useEffect(() => {
        setChecked(user.online || false);
    }, [user])

    const tabs = [
        {
            "name": "Pending", "key": 'pending'
        },
        {
            "name": "My Incidents", "key": 'active'
        }
    ]

    useEffect(() => {
        init_functions();

    }, [dispatch])

    async function getInitialData() {

        let last_refresh = localStorage.getItem(config.last_refresh);
        if (last_refresh) {
            var now = new Date()
            var when_last = new Date(JSON.parse(last_refresh));
            var hours = Math.abs((now - when_last) / 36e5);
            if (hours > 22) {
                refresh();
            } else {
                init_functions();
            }
        } else {
            refresh();
        }


    }

    function refresh() {
        refresh_token().then((response) => {
            var data = response.data;
            if (data.status === 'Success') {
                dispatch(auth_actions.actions.fulfillToken(data.data.access_token));
                localStorage.setItem(config.last_refresh, JSON.stringify(new Date()));
                init_functions();
            }
        }).catch((error) => {
            // console.log("Refresh token error", error)
            auth_actions.actions.logout()
        });
    }

    function init_functions() {
        // if (!categories || categories.length === 0) {
        //     dispatch(actions.fetchAllData('categories', '?active=true'));
        // }
        dispatch(actions.fetchAllData('active_danger', user.user_id));
        // dispatch(actions.fetchAllData('pending_danger', '?status=pending'));
        dispatch(actions.fetchAllData('pending_danger', user.user_id));    }


    const setUserAvailability = (checkState) => {
        const payload = {
            user_id: user.user_id,
            online: checkState,
        }
        setAvailability(payload)
            .then((response) => {
                var data = response.data;
                if (data && data.status === 'Success') {
                    getUserByToken().then((data) => {
                        if (data.status === 200) {
                            if (data.data.data) {
                                dispatch(auth_actions.actions.fulfillUser(data.data.data))
                            }
                        }
                    })
                    toast.success(data?.data || 'Successfully updated your availability');
                } else {
                    toast.error('Error updating your availability');
                }
            })
            .catch((error) => {
                var message = error?.response?.data?.message;
                // disableLoading();
            });
    }

    const updateChecked = () => {
        setChecked(!checked)
        setUserAvailability(!checked);
    }


    return (<>

        <div className="top-with-switch">
            <span className="text-dark font-weight-bold ml-5 mr-">
                Emergency Alerts
            </span>
            <div className="toggler-container">
                <input
                    checked={checked}
                    onChange={updateChecked}
                    // onChange={() => setChecked(!checked)}
                    className="switch-checkbox"
                    id={`switch`}
                    type="checkbox"
                />
                <label
                    style={{ background: checked ? '#2a8bf2' : '#c62828' }}
                    className="switch-label"
                    htmlFor={`switch`}
                >
                    <div className="off-on">
                        {!checked && <span className="off">Offline</span>}
                        {checked && <span className="on">Online</span>}
                    </div>
                    <span className={`switch-button`} />
                </label>
            </div>
        </div>

        <div style={{ padding: 20, width: '100%' }}>
            <div style={{
                border: '1px solid #F0EDFF',
                paddingVertical: 20,
                flexDirection: 'row',
                display: 'flex',
                height: 86,
                width: '100%'
            }}>
                {/* className = "text-center"  */}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, borderRight: '1px solid #F0EDFF' }}>
                    <SVG style={{ width: 32, height: 32, marginRight: 5 }} src={toAbsoluteUrl('/media/nav/users.svg')} />
                    <div>
                        <p style={{ fontSize: 10, marginBottom: 0 }}>Pending</p>
                        <h2 style={{ fontSize: 14, fontWeight: 600 }}>{pending_danger && pending_danger.meta ? pending_danger.meta.total_items : '...'}</h2>
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                    <SVG style={{ width: 32, height: 32, marginRight: 5 }} src={toAbsoluteUrl('/media/nav/users.svg')} />
                    <div>
                        <p style={{ fontSize: 10, marginBottom: 0 }}>Active</p>
                        <h2 style={{ fontSize: 14, fontWeight: 600 }}>{active_danger && active_danger.meta ? active_danger.meta.total_items : '...'}</h2>
                    </div>
                </div>
            </div>
        </div>

        <div style={{ padding: 20 }}>

            <Tab.Container defaultActiveKey={key}>
                <ul className="nav nav-tabs nav-bold nav-tabs-line">
                    {tabs.map((ele, i) => {
                        return (
                            <li
                                key={i}
                                className="nav-item">
                                <a className={`nav-link ${key === ele.key ? 'active' : ''}`} style={{ fontSize: 14 }} data-toggle="tab"
                                    onClick={() => setKey(ele.key)}
                                    href="javascript:;">{ele.name}</a>
                            </li>
                        )
                    })}
                </ul>

                <div className="p-4">
                    <Lists
                        action={() => init_functions()}
                        history={props.history}
                        type={key} />
                </div>

            </Tab.Container>



        </div>







    </>
    );
}
